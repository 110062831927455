/* Center the contents of the bubble chart */
.bubble-chart-container {
  flex: 1;
  position: relative;
  display: flex;
  user-select: none;
  justify-content: center;
}

/* Center text in the circle, and hide any overflow */
.bubble-chart-container .bubble-chart-text .bubble-label {
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  will-change: left, top, height, width;
}

/* Show the overflow when hovering over a label */
.bubble-chart-container .bubble-chart-text .bubble-label:hover {
  overflow: visible;
  font-weight: bold;
}

/* Optional, but recommended - reduce the size of text on medium bubbles */
.bubble-chart-container .bubble-chart-text .bubble-label.medium {
  font-size: 0.8em;
}

/* Optional, but recommended - hide the text on small bubbles */
.bubble-chart-container .bubble-chart-text .bubble-label.small {
  font-size: 0;
}
/* Make sure to unhide the text when hovering */
.bubble-chart-container .bubble-chart-text .bubble-label.small:hover {
  font-size: 0.8em;
}